import { CardListMinorScroll } from "components/card/card.list.minor.scroll";
import Status, {
  Props as StatusProps,
  StatusTypeEnum,
} from "components/shared/status";
import { useState } from "react";
import ContactForm from "../contact/contactForm";
import ExpandIcon from "../icons/expand.icon";
import Image from "../media/image";

type UsualProps = {
  title?: string;
  lede?: any;
  /**
   * This header has several variants for design reasons.
   * Default: Dark green
   * Event: Light green
   * White
   * Yellow
   */
  type: "default" | "event" | "white" | "yellow" | "event";
  featuredCourses?: any[];
  displayContactForm?: boolean;
  slim?: boolean;
  illustration?: any;
  form?: boolean;
};

type RenbladProps = {
  type: "renblad";
  title: string;
};

type Props = UsualProps | RenbladProps;

// Used to skip to main content by screen readers.
export const jumpTarget = "hovedinnhold";

const Header: React.FC<Props> = (props: Props) => {
  const [openForm, setOpenForm] = useState(false);
  const [status, setStatus] = useState<StatusProps>({
    type: StatusTypeEnum.NONE,
    message: "",
  });

  if (props.type === "renblad") {
    const { title } = props;
    return (
      <>
        <div>
          <Status message={status.message} type={status.type} />
        </div>
        <header id={jumpTarget} className="renblad-header">
          <div className="renblad-header__inner">
            {title && <h1 className="renblad-header__title">{title}</h1>}
          </div>
        </header>
      </>
    );
  }

  const {
    title,
    lede,
    type,
    displayContactForm = false,
    featuredCourses,
    slim = false,
    illustration,
  } = props;

  return (
    <>
      <div>
        <Status message={status.message} type={status.type} />
      </div>
      <header
        id={jumpTarget}
        className={`c-header c-header--${type} ${slim ? "c-header--slim" : ""}`}
      >
        <div
          className={[
            "o-wrapper",
            ...(title ? [] : ["o-wrapper--medium"]),
            ...(illustration ? ["c-header__with-illustration"] : []),
          ].join(" ")}
        >
          <div className="c-header__inner">
            {title && <h1 className={"c-header__title"}>{title}</h1>}

            {lede && <p className="u-rm-margin c-header__lede">{lede}</p>}
          </div>
          {illustration && (
            <div className="illustration">
              <Image image={illustration} alt={illustration.alt} />
            </div>
          )}
        </div>

        {featuredCourses && (
          <div className="o-wrapper u-padding-top--large u-padding-bottom--large">
            <CardListMinorScroll entries={featuredCourses} />
          </div>
        )}

        {displayContactForm && (
          <>
            <div className="o-wrapper--narrow u-margin-top--larger c-header__form-button">
              <button
                className={[
                  "c-open-form-button",
                  ...(openForm ? ["c-open-form-button--expanded"] : []),
                ].join(" ")}
                onClick={() => {
                  setOpenForm(!openForm);
                }}
              >
                Få passende abonnement
                <div
                  className={[
                    "c-open-form-icon",
                    ...(openForm ? ["c-open-form-icon--expanded"] : []),
                  ].join(" ")}
                >
                  <ExpandIcon />
                </div>
              </button>
            </div>
          </>
        )}
      </header>

      {displayContactForm && (
        <div className={openForm ? "c-header-form" : "u-hide"}>
          <ContactForm setOpenForm={setOpenForm} setStatus={setStatus} />
        </div>
      )}
    </>
  );
};

export default Header;
